import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-44d09d50"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_common_header = _resolveComponent("common-header");

  var _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_common_header), _createElementVNode("main", null, [_createVNode(_component_router_view)])]);
}