import _objectSpread from "C:/Users/10666/Desktop/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.find-index.js";
import { mapGetters } from "vuex";
import commonMobileNav from "@/components/common-mobile-nav";
export default {
  components: {
    commonMobileNav: commonMobileNav
  },
  data: function data() {
    return {
      visible: false,
      show: true,
      scrollLog: 0,
      scrollTop: false
    };
  },
  computed: _objectSpread({}, mapGetters({
    list: "navs",
    headerColor: "headerColor",
    productName: "productName",
    headerTopBtnWhite: "headerTopBtnWhite",
    isNews: "isNews"
  })),
  watch: {
    $route: function $route(to, from) {
      if (to.path !== from.path) {
        $(window).scrollTop(0);
      }
    }
  },
  methods: {
    openMenu: function openMenu() {
      this.visible = true;
    },
    navItemCurrent: function navItemCurrent(parse) {
      if (this.$route.path === "/product") {
        // console.log(this.productName, parse.name);
        return this.productName === parse.name;
      } else if (this.$route.path === "/serve") {
        // console.log(this.$route);
        return this.$route.fullPath === parse.to;
      } else {
        return this.$route.path === parse.to;
      }
    },
    navItemTopCurrent: function navItemTopCurrent(parse) {
      var _this = this;

      // console.log(parse.to1, parse.to, this.$route.path);
      if ([parse.to, "".concat(parse.to, "/"), parse.to1].includes(this.$route.path)) {
        return true;
      } else {
        var child = parse.child;
        var index = child && child.findIndex(function (item) {
          if (["/product", "/product/"].includes(_this.$route.path)) {
            return _this.productName === item.name;
          } else {
            // let res = item.to === this.$route.path;
            // console.log([item.to, `${item.to}/`], this.$route.path);
            var res = [item.to, "".concat(item.to, "/")].includes(_this.$route.path);

            if (!res && item.children) {
              res = item.children.findIndex(function (item) {
                return item.to === _this.$route.path;
              }) !== -1;
            }

            return res;
          }
        });
        return child && index !== -1;
      }
    },
    scrollFn: function scrollFn() {
      var scrollLog = this.scrollLog;
      var scroll = document.documentElement.scrollTop;

      if (scroll > scrollLog) {
        this.show = false;
      } else {
        this.show = true;
      }

      this.scrollLog = scroll;
      this.scrollTop = document.documentElement.scrollTop > 400; // this.show = document.documentElement.scrollTop <= 300;
    },
    to: function to(url) {
      this.$router.push(url);
    }
  },
  mounted: function mounted() {
    document.addEventListener("scroll", this.scrollFn);
  },
  unmounted: function unmounted() {
    window.removeEventListener("scroll", this.scrollFn);
  }
};