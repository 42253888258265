import _objectSpread from "C:/Users/10666/Desktop/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4c23fe53"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "left-title"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = {
  class: "btns"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  class: "introduction"
};
var _hoisted_7 = {
  class: "introduction-t"
};
var _hoisted_8 = ["innerHTML"];
var _hoisted_9 = {
  class: "text"
};
var _hoisted_10 = ["src", "alt"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "flex-rc area-container",
    style: _normalizeStyle(_objectSpread(_objectSpread({}, $options.containerReverse), $props.containerStyle))
  }, [_createElementVNode("div", {
    class: "left",
    style: _normalizeStyle($options.leftStyle)
  }, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [$props.t ? (_openBlock(), _createElementBlock("p", {
    key: 0,
    style: _normalizeStyle($options.textStyle.title),
    innerHTML: $props.t
  }, null, 12, _hoisted_2)) : _createCommentVNode("", true), $props.c ? (_openBlock(), _createElementBlock("p", {
    key: 1,
    style: _normalizeStyle($options.textStyle.title),
    innerHTML: $props.c
  }, null, 12, _hoisted_3)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.images, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["btn", {
        current: $data.current === index
      }]),
      key: index,
      onClick: function onClick($event) {
        return $data.current = index;
      },
      style: _normalizeStyle($props.btnStyle)
    }, _toDisplayString(item.t), 15, _hoisted_5);
  }), 128))]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("span", null, _toDisplayString(9 > $data.current ? "0" + ($data.current + 1) : $data.current + 1), 1), _createElementVNode("span", null, _toDisplayString($props.images[$data.current].t1 ? $props.images[$data.current].t1 : $props.images[$data.current].t), 1)]), _ctx.$slots.info ? _renderSlot(_ctx.$slots, "info", {
    key: 0,
    row: $props.images[$data.current].c
  }, undefined, true) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [$props.images[$data.current].c ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "introduction-c",
    innerHTML: $props.images[$data.current].c
  }, null, 8, _hoisted_8)) : _createCommentVNode("", true)], 64))])])], 4), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", {
    class: "c",
    style: _normalizeStyle($options.cStyle)
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.images, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass({
        current: index === $data.current
      }),
      key: item,
      ref_for: true,
      ref: 'r' + index,
      style: _normalizeStyle(_objectSpread({}, $options.cItemStyle))
    }, [_createElementVNode("img", {
      class: "c-image",
      src: item.img,
      alt: item.t
    }, null, 8, _hoisted_10)], 6);
  }), 128))], 4)])], 4);
}