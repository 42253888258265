import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1d8e9be6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "home"
};
var _hoisted_2 = {
  class: "pc"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_top = _resolveComponent("top");

  var _component_home_a = _resolveComponent("home-a");

  var _component_home_b = _resolveComponent("home-b");

  var _component_home_c = _resolveComponent("home-c");

  var _component_home_d = _resolveComponent("home-d");

  var _component_home_e = _resolveComponent("home-e");

  var _component_home_g = _resolveComponent("home-g");

  var _component_home_f = _resolveComponent("home-f");

  var _component_common_footer1 = _resolveComponent("common-footer1");

  var _component_common_back_top = _resolveComponent("common-back-top");

  var _component_tip_dialog = _resolveComponent("tip-dialog");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_top), _createVNode(_component_home_a), _createVNode(_component_home_b), _createVNode(_component_home_c), _createVNode(_component_home_d), _createVNode(_component_home_e), _createVNode(_component_home_g), _createVNode(_component_home_f), _createVNode(_component_common_footer1), _createVNode(_component_common_back_top), _createElementVNode("div", _hoisted_2, [_createVNode(_component_tip_dialog)])]);
}